import { ElMessage, ElMessageBox } from 'element-plus'

const Message = {

  Error: (msg: string) => {
    ElMessage.error({
      showClose: true,
      duration: 5000,
      center: true,
      grouping: true,
      message: msg,
    })
  },

  Success: (msg: string) => {
    ElMessage.success({
      center: true,
      grouping: true,
      message: msg,
    })
  },

  Warning: (msg: string) => {
    ElMessage.warning({
      center: true,
      grouping: true,
      message: msg,
    })
  },

  Info: (msg: string) => {
    ElMessage.info({
      center: true,
      grouping: true,
      message: msg,
    })
  },

  Confirm: (
    content: string,
    title = '提示',
    settings = {},
  ) => {
    return ElMessageBox.confirm(
      content,
      title,
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        showClose: true,
        type: 'warning',
        ...settings,
      },
    ).then(() => {
      return true
    }).catch(() => {
      return false
    })
  },
}

export default Message
