


export const layouts = {
'index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/index.vue'),
'components/AppSetting/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/AppSetting/index.vue'),
'components/Header/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/Header/index.vue'),
'components/HotkeysIntro/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/HotkeysIntro/index.vue'),
'components/Logo/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/Logo/index.vue'),
'components/MainSidebar/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/MainSidebar/index.vue'),
'components/Search/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/Search/index.vue'),
'components/SidebarItem/main': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/SidebarItem/main.vue'),
'components/SidebarItem/sub': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/SidebarItem/sub.vue'),
'components/SubSidebar/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/SubSidebar/index.vue'),
'components/Tools/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/Tools/index.vue'),
'components/Topbar/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/Topbar/index.vue'),
'components/views/iframe': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/views/iframe.vue'),
'components/views/link': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/views/link.vue'),
'components/Topbar/Toolbar/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/Topbar/Toolbar/index.vue'),
'components/Topbar/Tabbar/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/Topbar/Tabbar/index.vue'),
'components/Tools/Notification/index': () => import('/E:/User/source/vue-douyin-admin/src/layouts/components/Tools/Notification/index.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'index'],
      children: [ {...route, path: ''} ],
    }
  })
}
