const __pages_import_0__ = () => import("/src/views/video/list.vue");
const __pages_import_1__ = () => import("/src/views/scope/list.vue");
const __pages_import_2__ = () => import("/src/views/scope/detail.vue");
const __pages_import_3__ = () => import("/src/views/personal/setting.vue");
const __pages_import_4__ = () => import("/src/views/personal/notification.vue");
const __pages_import_5__ = () => import("/src/views/personal/edit.password.vue");
const __pages_import_6__ = () => import("/src/views/forward/list.vue");
const __pages_import_7__ = () => import("/src/views/reload.vue");
const __pages_import_8__ = () => import("/src/views/qrcode.vue");
const __pages_import_9__ = () => import("/src/views/login.vue");
import __pages_import_10__ from "/src/views/index.vue";
const __pages_import_11__ = () => import("/src/views/[...all].vue");

const routes = [{"name":"video-list","path":"/video/list","component":__pages_import_0__,"props":true},{"name":"scope-list","path":"/scope/list","component":__pages_import_1__,"props":true},{"name":"scope-detail","path":"/scope/detail","component":__pages_import_2__,"props":true},{"name":"personalSetting","path":"/personal/setting","component":__pages_import_3__,"props":true,"meta":{"title":"个人设置","cache":"personal-edit.password"}},{"name":"personalNotification","path":"/personal/notification","component":__pages_import_4__,"props":true,"meta":{"title":"通知中心"}},{"name":"personalEditPassword","path":"/personal/edit.password","component":__pages_import_5__,"props":true,"meta":{"title":"修改密码"}},{"name":"forward-list","path":"/forward/list","component":__pages_import_6__,"props":true},{"name":"reload","path":"/reload","component":__pages_import_7__,"props":true},{"name":"qrcode","path":"/qrcode","component":__pages_import_8__,"props":true},{"name":"login","path":"/login","component":__pages_import_9__,"props":true,"meta":{"whiteList":true,"title":"登录","constant":true,"layout":false}},{"name":"home","path":"/","component":__pages_import_10__,"props":true,"meta":{"title":"主页","icon":"ant-design:home-twotone"}},{"name":"notFound","path":"/:all(.*)*","component":__pages_import_11__,"props":true,"meta":{"title":"找不到页面","constant":true,"layout":false}}];

export default routes;