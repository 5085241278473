<route lang="yaml">
name: home
meta:
  title: 主页
  icon: ant-design:home-twotone
</route>

<script lang="ts" setup>
import qrcode from './qrcode.vue'
import api from '@/api'
import Message from '@/utils/messagebox'
import router from '@/router'
import eventBus from '@/utils/eventBus'
import useUserStore from '@/store/modules/user'

const userStore = useUserStore()
const qrcodeRef = ref()
const state = reactive({
  loading: false,

  videoWidth: 600,

  placeInfo: {
    code: '',
    name: '',
    videoid: {
      title: '',
      share_url: '',
      current: '',
      forward: 0,
      item_id: '',
      video_id: '',
      play_url: '',
      schema: '',
    },
  },
})

onMounted(() => {
  eventBus.on('get-IndexList-data', () => {
    getPlaceInfo()
  })
  getPlaceInfo()
  state.videoWidth = document.querySelector('.mainbox')?.clientWidth as number
})

onBeforeUnmount(() => {
  eventBus.off('get-IndexList-data')
})

async function getPlayUrl() {
  for (let i = 0; i < 3; i++) {
    const res = await api.post('/api/hasTop/', {
      barcode: state.placeInfo.code,
      host: 'boos',
    })
    if (res?.rescode === 200) {
      state.placeInfo.videoid.play_url = res?.play_url
      state.placeInfo.videoid.schema = res?.schema
      break
    }
  }
}

async function getPlaceInfo() {
  state.loading = true
  const res = await api.get('/api/hasMyPlace/', {
    params: {
      openid: userStore.open_id,
    },
  })
  if (res?.rescode === 200) {
    if (res?.resmsg?.length > 0) {
      state.placeInfo = res?.resmsg[0]?.place
      userStore.updateVideoitemId(state.placeInfo?.videoid?.item_id)
      console.log('场所信息 ', state.placeInfo)
      await getPlayUrl()
    }
    else {
      Message.Warning('还没有绑定场所')
      if (state.placeInfo?.code === '') {
        router.push({
          name: 'personalSetting',
        })
      }
    }
  }
  else if (res?.rescode === 401) {
    Message.Warning('还没有绑定场所')
    if (state.placeInfo?.code === '') {
      router.push({
        name: 'personalSetting',
      })
    }
  }
  else {
    Message.Error('获取场所信息失败')
  }
  state.loading = false
}

function makeQrcode() {
  qrcodeRef.value.openDialog(state.placeInfo.videoid.schema)
}
</script>

<template>
  <div v-loading="state.loading">
    <page-header :title="`${state.placeInfo?.name} 推广视频`">
      <template #content>
        <div v-if="state.placeInfo.code !== ''">
          <div style="margin-bottom: 5px;">
            视频标题：{{ state.placeInfo?.videoid?.title }}
          </div>
          <div style="margin-bottom: 5px;">
            视频链接：{{ state.placeInfo?.videoid?.play_url }}
          </div>
          <div style="margin-bottom: 5px;">
            推广时间：{{ state.placeInfo?.videoid?.current }}
          </div>
          <div style="margin-bottom: 5px;">
            转发次数：{{ state.placeInfo?.videoid?.forward }}
          </div>
        </div>
      </template>
      <el-button type="primary" size="default" @click="makeQrcode">
        生成二维码
      </el-button>
    </page-header>
    <div class="mainbox">
      <VideoPlay
        v-show="true"
        :url="state.placeInfo.videoid?.play_url"
        :width="state.videoWidth"
        :height="500"
        zoom="catain"
      />
    </div>

    <qrcode ref="qrcodeRef" />
  </div>
</template>

<style lang="scss" scoped>
.mainbox{
  margin: auto 20px;
  width: 60%;
}

.text-emphasis {
  text-emphasis-style: "❤";
}

.ecology {
  padding: 10px 0 0;

  &.vue {
    h1 {
      color: #41b883;
    }
  }

  &.fa {
    h1 {
      color: #e60000;
    }
  }

  &.osa {
    h1 {
      color: #67c23a;
    }
  }

  .main {
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
    }

    h1 {
      margin: 10px auto 0;
      text-align: center;
    }

    h2 {
      font-size: 16px;
      font-weight: normal;
      color: var(--el-text-color-secondary);
      text-align: center;
    }
  }

  .el-carousel {
    box-shadow: var(--el-box-shadow-light);
    transition: var(--el-transition-box-shadow);
  }

  ul li {
    line-height: 28px;
  }
}

.question {
  .answer {
    margin: 20px 0 0;
    padding-left: 20px;
    font-size: 16px;
    color: var(--el-text-color-secondary);

    li {
      margin-bottom: 10px;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }
    }

    span {
      color: var(--el-text-color-primary);
      font-weight: bold;
    }
  }
}
</style>
