import axios from 'axios'
// import qs from 'qs'
import { ElMessage } from 'element-plus'
import router from '@/router/index'
import useSettingsStore from '@/store/modules/settings'
import useUserStore from '@/store/modules/user'

const toLogin = () => {
  useUserStore().logout().then(() => {
    router.push({
      path: '/login',
      query: {
        redirect: router.currentRoute.value.path !== '/login' ? router.currentRoute.value.fullPath : undefined,
      },
    })
  })
}

// 解决 类型“AxiosResponse<any, any>”上不存在属性的提示
declare module 'axios' {
  // interface AxiosInstance {
  //   (config: AxiosRequestConfig): Promise<any>
  // }
  interface AxiosResponse {
    (config: AxiosResponse): Promise<any>
    rescode: number
    resmsg: any
    play_url: string
    schema: string
  }
}

const api = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASEURL,
  timeout: 1000 * 60,
  responseType: 'json',
})

api.interceptors.request.use(
  (request) => {
    const lastUrlChar = request.url?.charAt(request.url.length - 1)
    if (lastUrlChar !== '/') {
      request.url += '/'
    }

    // 全局拦截请求发送前提交的参数
    const settingsStore = useSettingsStore()
    const userStore = useUserStore()
    // 设置请求头
    if (request.headers) {
      request.headers['Accept-Language'] = settingsStore.settings.app.defaultLang
      if (userStore.isLogin) {
        request.headers.Authorization = `Bearer ${userStore.open_id}`
      }
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (request.method === 'post') {
      // request.data = qs.stringify(request.data, {
      //   arrayFormat: 'brackets',
      // })
    }

    console.log('request. ', request)
    return request
  },
)

api.interceptors.response.use(
  (response) => {
    console.log('response. ', response)
    /**
     * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
     * 假设返回数据格式为：{ status: 1, error: '', data: '' }
     * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
     * 请求出错时 error 会返回错误信息
     */
    if (response.data.rescode === 400) {
      if (response.data.resmsg?.Authentication === '用户未登录或登录失效') {
        toLogin()
      }
    }

    return Promise.resolve(response.data)
  },
  (error) => {
    let message = error.message
    if (message === 'Network Error') {
      message = '后端网络故障'
    }
    else if (message.includes('timeout')) {
      message = '接口请求超时'
    }
    else if (message.includes('Request failed with status code')) {
      message = `接口${message.substr(message.length - 3)}异常`
    }
    ElMessage({
      message,
      type: 'error',
    })
    return Promise.reject(error)
  },
)

export default api
