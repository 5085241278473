import useTabbarStore from './tabbar'
import useRouteStore from './route'
import useMenuStore from './menu'
import api from '@/api'
import storage from '@/utils/storage'

const useUserStore = defineStore(
  // 唯一ID
  'user',
  {

    state: () => ({
      permissions: <any>[],
      userInfo: storage.local.has('userInfo')
        ? JSON.parse(storage.local.get('userInfo') as string)
        : {
            avatar: '',
            nickname: '',
          },
      open_id: storage.local.get('open_id') ?? '',
      video_item_id: storage.local.get('video_item_id') ?? '',
    }),
    getters: {
      isLogin: (state) => {
        let retn = false
        if (state?.open_id !== '') {
          retn = true
        }
        return retn
      },
    },
    actions: {
      // 登录
      async login(open_id: string) {
        this.open_id = open_id
      },

      // 获取并保存用户信息i
      async getUserInfo(open_id: string) {
        this.open_id = open_id
        storage.local.set('open_id', this.open_id)

        const res = await api.post('/api/hasUserInfo/')
        console.log('用户信息: ', res)
        if (res?.rescode === 200) {
          this.userInfo.avatar = res?.resmsg?.avatar
          this.userInfo.nickname = res?.resmsg?.nickname
          storage.local.set('userInfo', JSON.stringify(this.userInfo))
        }
      },

      // 登出
      async logout() {
        storage.local.remove('userInfo')
        this.userInfo = {}
        storage.local.remove('open_id')
        this.open_id = ''
        storage.local.remove('video_item_id')
        this.video_item_id = ''

        const tabbarStore = useTabbarStore()
        const routeStore = useRouteStore()
        const menuStore = useMenuStore()
        tabbarStore.clean()
        routeStore.removeRoutes()
        menuStore.setActived(0)
      },

      // 更新当前推广视频虚拟ID
      updateVideoitemId(item_id: string) {
        if (item_id && item_id !== '') {
          this.video_item_id = item_id
          storage.local.set('video_item_id', item_id)
        }
      },

      getPermissions() {
        return <any>[]
      },
    },
  },

)

export default useUserStore
