import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/video',
  component: Layout,
  redirect: '/video/list',
  name: 'Video',
  meta: {
    title: '视频管理',
    i18n: 'route.multimenu.root',
    icon: 'ic:outline-ondemand-video',
  },
  children: [
    {
      path: 'list',
      name: 'VideoList',
      component: () => import('@/views/video/list.vue'),
      meta: {
        title: '视频列表',
        sidebar: false,
        activeMenu: '/video',
      },
    },
  ],
}

export default routes
