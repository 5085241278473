import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/scope',
  component: Layout,
  redirect: '/scope/list',
  name: 'Scope',
  meta: {
    title: '授权管理',
    i18n: 'route.multimenu.root',
    icon: 'ic:outline-ondemand-video',
  },
  children: [
    {
      path: 'list',
      name: 'ScopeList',
      component: () => import('@/views/scope/list.vue'),
      meta: {
        title: '授权列表',
        sidebar: false,
        activeMenu: '/scope',
      },
    },
  ],
}

export default routes
