<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'VideoPlay',
})
</script>
<script lang="ts" setup name="VideoPlay">
const props = defineProps({
  url: { // 视频文件链接
    type: String,
    require: true,
    default: '',
  },
  cover: { // 视频封面图片
    type: String,
    default: '',
  },
  width: {
    type: Number,
    default: 500,
  },
  height: {
    type: Number,
    default: 800,
  },
  autoplay: { // 通过默认静音来实现自动播放
    type: Boolean,
    default: false,
  },
  controls: { // 显示控制面板
    type: Boolean,
    default: true,
  },
  loop: { // 循环播放
    type: Boolean,
    default: false,
  },
  muted: { // 是否静音
    type: Boolean,
    default: true,
  },
  preload: { // 是否在页面加载后载入视频，如果设置了autoplay，则preload被忽略
    type: String,
    default: 'auto',
  },
  showPlay: { // 暂停时是否在中间显示播放暂停图标
    type: Boolean,
    default: false,
  },
  playWidth: { // 中间播放暂停按钮边长
    type: Number,
    default: 96,
  },
  zoom: {
    type: String,
    default: 'none',
  },
})

const veo = ref()
const state = reactive({
  originPlay: true,
  vplay: false,
})

onMounted(() => {
  if (props.showPlay) {
    veo.value.addEventListener('pause', onPause)
    veo.value.addEventListener('playing', onPlaying)
  }
  if (props.autoplay) {
    state.vplay = true
    state.originPlay = true
  }
})

function onPlay() {
  state.vplay = true
  state.originPlay = false
  veo.value.play()
}
function onPause() {
  state.vplay = false
  onBeforeUnmount(() => {
    removeEventListener('pause', onPause)
  })
}
function onPlaying() {
  state.vplay = true
  onBeforeUnmount(() => {
    removeEventListener('playing', onPlaying)
  })
}
</script>

<template>
  <div class="m-video" :style="`width:${width}px; height:${height}px;`">
    <video
      ref="veo"
      :style="`object-fit: ${zoom}`"
      :src="url"
      :poster="cover"
      :width="width"
      :height="height"
      :autoplay="autoplay"
      :controls="!state.originPlay && controls"
      :loop="loop"
      :muted="autoplay || muted"
      :preload="preload"
      @click.prevent.once="onPlay"
    >
      <meta name="referrer" content="never">
    </video>
    <svg v-show="state.originPlay || showPlay" :class="[state.vplay ? 'hidden' : 'u-play']" :style="`width: ${playWidth}px; height: ${playWidth}px;`" viewBox="0 0 24 24">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75Z" />
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.25 12L9.75 8.75V15.25L15.25 12Z" />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.m-video {
  display: inline-block;
  position: relative;
  background: #000;
  cursor: pointer;

  &:hover {
    .u-play {
      opacity: 0.9;
    }
  }

  .u-play {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    fill: none;
    color: #FFF;
    pointer-events: none;
    opacity: 0.7;
    transition: opacity .3s;
  }

  .hidden {
    display: none;
  }
}
</style>
